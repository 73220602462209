.tx-fancy {
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
}

.tx-center {
  text-align: center;
}

.tx-xlrg {
  font-size: 3.5em;
}

.tx-lrg {
  font-size: 2.5em;
}

.tx-med {
  font-size: 2.05em;
}

.tx-reg {
  font-size: 1.5em;
}

.tx-sm {
  font-size: 1em;
}

.tx-tiny {
  font-size: 0.65em;
}

.tx-orange {
  color: #e16a2c;
}

.tx-red {
  color: #960a14;
}

.tx-white {
  color: #ffffff;
}

.tx.black {
  color: #000000;
}

.tx-green {
  color: #3A7A42;
}

.tx-purple {
  color: #630ea4;
}

.bg-purple {
  background-color: #630ea4;
}

.bg-purple-g {
  background: rgb(99, 14, 164);
  background: -moz-linear-gradient(top, rgba(99, 14, 164, 1) 0%, rgba(60, 8, 100, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(99, 14, 164, 1) 0%, rgba(60, 8, 100, 1) 100%);
  background: linear-gradient(to bottom, rgba(99, 14, 164, 1) 0%, rgba(60, 8, 100, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3c0864', endColorstr='#630ea4', GradientType=0);
}