
header {

  @include gradient-vertical($theme-blue, $theme-dark-blue, 0%, 100%);

  nav {
    @include gradient-vertical($nav-green, $nav-dark-green, 0%, 100%);

    a {
      color: #fff;
    }

  }

  .pop-up-nav {
    position: absolute;
    z-index: 10;
    width: 180px;
  }

  h1, h3, .top-nav a ,.aaa-logo {
    color: #fff;
  }

  h1 {
    font-size: $font-size-h3;
    font-family: sans-serif;
    font-weight: 900;
    @extend .buffer-sm;
    margin-left: 0;
  }

  h3 {
    font-size: $font-size-large;
    @extend .buffer-void;
  }

  .navbar{
    border: 0px solid transparent;
    border-bottom: 5px solid $theme-dark-blue;
    border-top: 5px solid $theme-dark-blue;
    border-radius: 0;

    .navbar-brand {
      padding: 0;

      img {
        height: 50px;
        //padding: 10px;
      }
    }

    .navbar-nav {
      display: inline-block;
      float: none;
      vertical-align: top;

      //change nav default colors
      li > a {
        color: #fff;
      }

      .active > a {
        background: rgba(1,1,1,0.1);
        color: #fff
      }

      .active > a:hover {
        background: rgba(1,1,1,0.1);
      }
      .dropdown-menu {
        background: $nav-dark-green;
      }
    }
    .navbar-collapse {
      text-align: center;
    }
    .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus, .nav .open > a, .nav .open > a:hover, .nav .open > a:focus  {
      background-color: $nav-dark-green;
      color: #fff;
    }

  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    background: $nav-green;
  }

  @media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #fff;
    }
  }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }




  //  ***************************************************************** banners

  .banner {
    background: url("../../../images/home-background.jpg") no-repeat center;
    background-size: cover;
    height: 325px;
    border-radius: $border-radius-base;

    .side-image img {
      width: 70%;
      max-height: 100%;
    }

    .state-logo img {
      width: 85%;
    }

    .state-logos img{
      max-height: 85px;
    }

    .state-logos>div{
      display: inline-block;
    }

    .checklist ul {
      list-style: url("../../../images/tick.png");
      list-style-position: inside;
      margin: 0;
      padding: 0;
    }

    .payment-methods{
      margin-top: 20px;
      max-height: 30px;
    }

    .price {
      font-weight: 700;
      @extend .buffer-top-md;

      h3 {
        color: #111111;
        font-size: $font-size-h3
      }

      span {
        font-size: $font-size-h1;
      }
    }

    .register {
      
      button {
        @include gradient-vertical(#CA0000, #9A0000, 0%, 100%);
        border: none;
        font-weight: bold;
        color: #fff;
        width: 100%;
        font-size: $font-size-large;
      }
    }

    h4 {
      margin: auto;
    }
  }

}