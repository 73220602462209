.container {
  max-width: 1140px;
}

#main-logo {
  @media (max-width: $screen-sm-min) {
    max-height: 50px;
  }
}

//eliminates spacing on top of content area
.content-landing .row:first-child {
  margin-top: 0;
}

// ***************************************************************** content

.outer-container {
  background: url("../../../images/driving-gradient.jpg") no-repeat center fixed;
  background-size: cover;

  .container {
    background-image: -ms-linear-gradient(top, #D9EDBF 0%, #DFF0C8 3%, #FCFCFC 8%);
    background-image: -moz-linear-gradient(top, #D9EDBF 0%, #DFF0C8 3%, #FCFCFC 8%);
    background-image: -o-linear-gradient(top, #D9EDBF 0%, #DFF0C8 3%, #FCFCFC 8%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #D9EDBF), color-stop(3, #DFF0C8), color-stop(8, #FCFCFC));
    background-image: -webkit-linear-gradient(top, #D9EDBF 0%, #DFF0C8 3%, #FCFCFC 8%);
    background-image: linear-gradient(to bottom, #D9EDBF 0%, #DFF0C8 3%, #FCFCFC 8%);
  }

  .content {

    //@extend .buffer-bottom-lg;

    .row {
      @extend .buffer-lg;

      @media (max-width: $screen-sm-min) {
        margin: 5px;
      }
    }

    //************************************************************* boxes

    .box {
      h5 {
        color: $theme-blue;
        font-size: $font-size-h3;
        font-family: 'Lusitana', serif;
      }

    }

    .box-default {

    }

    .separator {
      border: 1px solid $theme-grey;
      height: 1px;
    }

    .box-carved {
      background: #f5f5f5;
      border: 1px solid $theme-grey;
      border-radius: $border-radius-base;
      @extend .bosom;
      h6 {
        font-size: $font-size-large;
      }
    }

    .box-titled {
      @extend .bosom-void;
      border: 1px solid $theme-grey;
      border-radius: $border-radius-base;

      .title {
        color: #fff;
        background: $nav-green;
        @extend .bosom;
        @extend .buffer-void;
        border-radius: $border-radius-base $border-radius-base 0 0;
      }

      div {
        @extend .bosom;
        background: #fff;
      }
    }

    //************************************************************* other

    .shadow {
      -webkit-box-shadow: 0px 0px 29px -2px rgba(77, 72, 77, 0.62);
      -moz-box-shadow: 0px 0px 29px -2px rgba(77, 72, 77, 0.62);
      box-shadow: 0px 0px 29px -2px rgba(77, 72, 77, 0.62);
    }

  }
}
