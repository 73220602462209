.list-in-2-col {
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -o-column-count: 2;
  -o-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
}

.list-in-3-col {
  -webkit-column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-count: 3;
  -moz-column-gap: 15px;
  -o-column-count: 3;
  -o-column-gap: 15px;
  column-count: 3;
  column-gap: 15px;
}

.list-in-5-col {
  -webkit-column-count: 5;
  -webkit-column-gap: 20px;
  -moz-column-count: 5;
  -moz-column-gap: 20px;
  -o-column-count: 5;
  -o-column-gap: 20px;
  column-count: 5;
  column-gap: 20px;
}