.fancy-grid {
  .fancy-steps-ul ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    b {
      color: $nav-green;
    }
  }

  .fancy-checkbox-ul ul{
    list-style-type: none;
    li:before {


      font-family: 'FontAwesome';
      content: '\f096';
      margin: 0 5px 0 -15px;
    }
    margin: 0px 0px 0px -30px;
  }

  .fancy-no-bullets ul{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }
}