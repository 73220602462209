.course {

  .outer-container .container {

    margin-bottom: 0;
    background: #fff;

    .row {
      @extend .buffer-void;
    }

    .timer {
      font-size: $font-size-h3;
      color: $theme-blue;
    }

    .right-panel {
      background: #004D67;
      color: #acacac;
      font-size: $font-size-large;
      border-radius: $border-radius-large;

      ul.course-instructions {
          list-style: decimal;
          font-size: 14px;
        span {
          font-weight: 700;
        }
      }
    }

    .left-panel {
      //text-align: center;

      padding-left: 0px;
      padding-right: 0px;

      ul.lessons {
        list-style: none;
        font-size: $font-size-large;

        li {
          //@extend .buffer-md;
          line-height: 20px;
        }

        li.pending {
          list-style: url("../../../images/x.png");
        }

        li.passed {
          list-style: url("../../../images/check.png");
        }
        
      }

    }
  }
}

