.course {

  ul.questions {
    list-style: decimal;

    li.question {
      @extend .buffer-lg;
      font-size: $font-size-large;

      label {
        font-weight: 500;
        font-size: $font-size-base;
      }
    }
  }

}

