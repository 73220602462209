
//*********************************************** footer

footer {
  @extend .bosom;

  background: $theme-blue;
  text-align: center;

  a, span {
    color : #fff;
  }

  .info {
    font-size: $font-size-small;
    color: #BEBEBE;

    p {
      @extend .buffer-void;
      line-height: 1;
    }
  }

  .list-inline > li {
    padding: 0px;
  }

  .link-list > li > a {
    font-size: 14px;
    padding-right: 2px;
    padding-left: 5px;
  }

  .category{
    color: #BEBEBE;
  }
}


.course footer {

  @extend .buffer-void;

  background: transparent;

  a {
    color : #428bca !important;
    font-size: $font-size-large;
  }

  span, ul.link-list li:not(:first-child)::before{
    color: #131313;
    font-size: $font-size-large;
  }

  .info {
    width: 50%;
    font-size: $font-size-base;
    color: #131313;
    margin: auto;

    p {
      @extend .buffer-void;
      line-height: 2;
    }
  }

}
