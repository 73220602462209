//***************************************************** lists

.multiple-choices {
  list-style: circle;
}


.horizontal > * {
  display: inline;
}

.horizontal dd {
  margin-right: 15%;
}

.link-list {
  li a {
    padding-left: 10px;
  }

  li:not(:first-child)::before {
    content: "|";
    color: #fff;
  }
}

.horizontal-pairs {
  dt {
    font-size: $font-size-h2;
    color: $nav-green;
    display: inline;

    span {
      font-size: $font-size-large;
      @extend .buffer-left;
    }
  }

  dd {
    display: inline;
  }

}
